import React, { useState, useEffect, createContext, useContext } from "react";
import { Link } from 'react-router-dom';
import LoginForm from "./LoginForm";
import { useStateValue } from "../context/StateProvider";
import { requestSubscriptionApproval, checkSubscriptionStatus, fetchTargetUser, fetchSubscribers, fetchUserBasedSubscriptionRequests } from '../utils/FirebaseFunction'; // Ensure these functions are correctly implemented
import { hashString } from "./utilities";
import { useUser } from './context/UserContext'; // useUser hook'unu içeri aktarın
import CryptoJS from "crypto-js";

const UserPage = () => {
  const [targetUserName, setTargetUserName] = useState(""); // Subscriber's first name
  const [targetUserSurname, setTargetUserSurname] = useState("");  // Subscriber's last name
  const [targetUserId, setTargetUserId] = useState(null)
  const [targetUserSubscriptionRequestStatus, setTargetUserSubscriptionRequestStatus] = useState(null);  // To show request status (pending, success, error)
  const [targetUserStatus, setTargetUserStatus] = useState(null);  // To show if an active subscription already exists
  const [subscribers, setSubscribers] = useState([]); // State to store fetched subscribers
  const [message, setMessage] = useState("");  // Store the message returned from the subscription request
  const [{ user }] = useStateValue();
  const { subscriberInfos, setUserInfo } = useUser();
  const [pendingRequests, setPendingRequests] = useState([]);


  // Fetch pending requests on component mount and listen for updates

    // Define the callback function
    const myCallback = (fetchedRequests) => {
      setPendingRequests(fetchedRequests);  // Update the state with fetched requests
    };

// UserPage.js
useEffect(() => {
  if (!user || !user.uid) return;  // Ensure user and user.uid are valid

  const unsubscribeRequests = fetchUserBasedSubscriptionRequests(user.uid, (fetchedRequests) => {
    setPendingRequests(fetchedRequests);  // Set the fetched requests
  });

  // Cleanup Firestore listener on unmount or user.uid change
  return () => {
    if (unsubscribeRequests) unsubscribeRequests();
  };
}, [user]);  // Runs when the user state or user.uid changes

useEffect(() => {
  if (!user || !user.uid) return;  // Ensure user and user.uid are valid

  const unsubscribeSubscribers = fetchSubscribers(user.uid, (fetchedSubscribers) => {
    setSubscribers(fetchedSubscribers);  // Set the fetched subscribers
  });

  // Cleanup Firestore listener on unmount or user.uid change
  return () => {
    if (typeof unsubscribeSubscribers === 'function') unsubscribeSubscribers();
  };
}, [user]);  // Runs when the user state or user.uid changes
  

  const handleSubscriberClick = (subscriber) => {
    setUserInfo(subscriber); // Set only the clicked subscriber's info in the context
  };

  const formatDate = (timestamp) => {
    const date = timestamp.toDate();  // Convert Firestore Timestamp to Date
    return date.toLocaleDateString('tr-TR');  // Format the date as a string
  };

  // Handle subscription request  
  const handleRequestSubscription = async () => {
    if (targetUserName && targetUserSurname) {
      // const generatedTargetUserId = hashString(`${targetUserName}-${targetUserSurname}`);
      const hashString = (inputString) => {
        // SHA-256 algoritmasıyla hashle
        const hash = CryptoJS.SHA256(inputString).toString(CryptoJS.enc.Base64);
        
        // URL-safe hale getirmek için özel karakterleri değiştir
        const safeHash = hash
          .replace(/\+/g, '1')   // "+" karakterini "-" ile değiştir
          .replace(/\//g, '2')   // "/" karakterini "_" ile değiştir
          .replace(/=+$/, '3')    // "=" karakterini kaldır
          .replace(/-/g, '4');   // "-" karakterini "x" ile değiştir (istediğiniz harf veya rakam ile değiştirebilirsiniz)
        
        return safeHash; // URL-safe hash döndür
      };
      
      // Örnek kullanım
      const generatedTargetUserId = hashString(`${targetUserName}-${targetUserSurname}`);
      setTargetUserId(generatedTargetUserId);
       
      // Helper function to get subscription status and target user existence
      const checkStatus = async () => {
        try {
          const subscriptionStatus = await checkSubscriptionStatus(generatedTargetUserId);  
          const targetUserExists = await fetchTargetUser(user.uid, generatedTargetUserId);
  
          return { subscriptionStatus, targetUserExists };
        } catch (error) {
          console.error("Error checking target user's subscription status:", error);
          return { subscriptionStatus: false, targetUserExists: false };
        }
      };
  
      // Call checkStatus and use the returned values directly
      const { subscriptionStatus, targetUserExists } = await checkStatus();
  
      if (subscriptionStatus) {
        setMessage(`Şu an ${targetUserName} ${targetUserSurname} için admin onayında bekleyen abonelik talebiniz var. Onay veya ret verilmeden tekrar talep oluşturulamaz.`);
        setTimeout(() => setMessage(""), 7000);
        return;
      } else if (targetUserExists) {
        setMessage("Bu kullanıcı için geçerli bir abonelik var.");
        setTimeout(() => setMessage(""), 5000);
        return;
      } else {

        try {
          const result = await requestSubscriptionApproval(
            user.uid, 
            user.email, 
            user.hashedUserId, 
            user.displayName, 
            targetUserName, 
            targetUserSurname, 
            generatedTargetUserId
          );
          
          setMessage(result.message);
          setTimeout(() => setMessage(""), 5000);
        
          // Mail gönderimi
          try {
            // const response = await fetch('http://localhost:5000/send-subscription-request', {
            const response = await fetch('https://www.msc.barruno.com/send-subscription-request', {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({
                subscriberEmail: user.email,
                targetUserName,
                targetUserSurname,
              }),
            });
            
            if (!response.ok) {
              throw new Error("Mail gönderimi başarısız");
            }
          } catch (mailError) {
            console.error("Mail gönderim hatası:", mailError);
          }
        } catch (error) {
          setMessage("Bir hata oluştu. Lütfen tekrar deneyin.");
          console.error("Error during subscription request:", error);
        }
        



      }
    } else {
      message("Lütfen isim ve soyisim giriniz.");
      setTimeout(() => setMessage(""), 5000);
    }
  };    

  return (
  <>
    {/* LoginForm Butonu */}
    <div className='p-2 mb-2 flex justify-end'>
          <LoginForm buttonClass=" top-2 right-2" />   
        </div>
        <div className="p-6 mt-8 rounded-lg shadow-lg text-black">
          {/* Subscription Request Form */}
          <div>
          <h2 className="text-xl font-semibold text-center mb-4">Sayın {user.displayName}</h2>
            <h2 className="font-semibold  mb-4">Abonelik almak istediğiniz kişinin isim ve soyismini giriniz.</h2>
          </div>
          <div className="mt-4 mb-4">
            <label className="block text-sm font-medium text-black">Anı almak istediğiniz kişinin İsmi:</label>
            <input
              type="text"
              value={targetUserName}
              onChange={(e) => setTargetUserName(e.target.value)}
              className="block w-full text-md text-black border border-gray-300 rounded-lg p-2 focus:ring-blue-500 focus:border-blue-500"
              placeholder="İsim"
            />
          </div>
          <div className="mb-4">
            <label className="block text-sm font-medium text-black">Anı almak istediğiniz kişinin Soyismi:</label>
            <input
              type="text"
              value={targetUserSurname}
              onChange={(e) => setTargetUserSurname(e.target.value)}
              className="block w-full text-md text-black border border-gray-300 rounded-lg p-2 focus:ring-blue-500 focus:border-blue-500"
              placeholder="Soyisim"
            />
          </div>
          <button
            onClick={handleRequestSubscription}
            className="w-full py-2 px-4 bg-green-600 text-white rounded-lg hover:bg-green-700 transition duration-200 mb-2"
            disabled={!targetUserName || !targetUserSurname}
          >
            1 yıllık abonelik al - 1000 TL
          </button>
          {message && (
            <p className={`text-center ${targetUserStatus ? 'bg-green-300' : 'bg-red-300'} font-semibold`}>
              {message}
            </p>
          )}

    
  {/* Subscription Requests waiting for approval */}
  {pendingRequests && pendingRequests.length > 0 && (
    <div className="mt-8">
      <p className="font-semibold mb-1">Admin Onayında Bekleyen Abonelik Başvuruları</p>
      {/* Tabloyu taşmasını önlemek için bir scrollable container'a alıyoruz */}
      <div className="overflow-x-auto">
        <table className="w-full table-auto text-left border border-gray-300">
          <thead>
            <tr className="text-sm sm:text-base">
              <th className="px-1 border-b">Abone İsmi</th>
              <th className="px-1 border-b">Abone Soyismi</th>
              <th className="px-1 border-b">Abonelik Başvuru Tarihi</th>
              <th className="px-1 border-b">Abonelik Durumu</th>
            </tr>
          </thead>
          <tbody>
            {pendingRequests.map((pendingRequest) => (
              <tr className="text-sm sm:text-base" key={pendingRequest.id}>
                <td className="px-1 border-b">{pendingRequest.targetUserName}</td>
                <td className="px-1 border-b">{pendingRequest.targetUserSurname}</td>
                <td className="px-1 border-b">{formatDate(pendingRequest.requestDate)}</td>
                <td className="px-1 border-b">{pendingRequest.status}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  )}


  {/* Subscriber List */}
  {subscribers && subscribers.length > 0 && (
  <div className="overflow-x-auto mt-8">
  <p className="font-semibold mb-1">Mevcut Aboneler</p>
  <table className="table-fixed w-full text-left border border-gray-300">
    <thead>
      <tr className="text-sm sm:text-base">
        <th className="px-1 border-b">Abone İsmi</th>
        <th className="px-1 border-b">Abone Soyismi</th>
        <th className="px-1 border-b">Abonelik Bitiş Tarihi</th>
        <th className="px-1 border-b">Yüklenen İçerik</th>
      </tr>
    </thead>
    <tbody>
      {subscribers.map((subscriber) => (
        <tr key={subscriber.id} className="text-sm sm:text-base">
          <td className="break-words whitespace-normal px-1 border-b">
            {subscriber.name}
          </td>
          <td className="break-words whitespace-normal px-1 border-b">
            {subscriber.surname}
          </td>
          <td className="px-1 border-b">
            {formatDate(subscriber.subscriptionEndDate)}
          </td>
          <td className="px-1 border-b">
            <Link
              to={`/userContent/${user.hashedUserId}/${subscriber.id}/content`}
              className="text-blue-600 hover:underline"
              onClick={() => handleSubscriberClick(subscriber)}
            >
              Yüklenen İçerik
            </Link>
          </td>
        </tr>
      ))}
    </tbody>
  </table>
</div>

    )}

    </div>
  </>
  );
};

export default UserPage;
