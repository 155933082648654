import React, { useEffect, useState, useRef } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { fetchTargetUserContent } from '../utils/FirebaseFunction';


const ContentPage = () => {
  const location = useLocation();
  const { hashedUserId, targetUserId } = useParams();
  const [content, setContent] = useState([]);

  // Refs for the audio elements
  const audioRef1 = useRef(null);
  const audioRef2 = useRef(null);

  useEffect(() => {
    const fetchContent = async () => {   
      const contentList = await fetchTargetUserContent(hashedUserId, targetUserId);
      setContent(contentList);
    };
    fetchContent();
  }, [hashedUserId]);


  const handleAudioPlay = (currentAudioRef, otherAudioRef) => {
    if (otherAudioRef.current && !otherAudioRef.current.paused) {
      otherAudioRef.current.pause();
    }
  };


  console.log("CONTENT PAGE'TEKİ content : ", content)
  return (
    <div className="relative min-h-screen flex flex-col items-center py-10 px-4 md:px-10">
      {/* Background Video */}
      <video
        autoPlay
        loop
        muted
        className="absolute inset-0 w-full h-full object-cover z-0"
      >
        <source src="/videos/bgvideo.mp4" type="video/mp4" />
        Your browser does not support the video tag.
      </video>

      {/* Dark Overlay */}
      <div className="absolute inset-0 bg-black opacity-50 z-0"></div>

      {/* Content Overlay */}
      <div className="relative z-10 text-white">
        <h2 className="text-3xl md:text-4xl font-bold text-white mb-8 text-center">
          İçerik
        </h2>

        {/* Audio Files */}
        <div className="mb-2 flex flex-col md:flex-row md:justify-center md:space-x-8 space-y-4 md:space-y-0">
          <div className="text-center">
            <p className="text-lg font-semibold">Ayetel Kursi</p>
            <audio
              ref={audioRef1}
              controls
              onPlay={() => handleAudioPlay(audioRef1, audioRef2)}
            >
              <source src="/music/AyetelKursi.mp3" type="audio/mpeg" />
              Tarayıcınız ses öğesini desteklemiyor.
            </audio>
          </div>
          <div className="text-center">
            <p className="text-lg font-semibold">Yasin Suresi</p>
            <audio
              ref={audioRef2}
              controls
              onPlay={() => handleAudioPlay(audioRef2, audioRef1)}
            >
              <source src="/music/YasinSuresi.mp3" type="audio/mpeg" />
              Tarayıcınız ses öğesini desteklemiyor.
            </audio>
          </div>
        </div>

        {content.length > 0 ? (
          <div className="max-w-6xl grid gap-6 sm:grid-cols-2 lg:grid-cols-3">
            {content.map((item, index) =>
              item.media.map((media, idx) => {
                if (media.type === "image") {
                  return (
                    <img
                      key={`${index}-${idx}`}
                      src={media.path}
                      alt={`User content ${idx}`}
                      className="w-80 h-80 object-cover rounded-md shadow transition-transform transform hover:scale-105"
                    />
                  );
                } else if (media.type === "video") {
                  return (
                    <video
                      key={`${index}-${idx}`}
                      src={media.path}
                      controls
                      className="w-80 h-80 object-cover rounded-md shadow transition-transform transform hover:scale-105"
                    />
                  );
                }
                return null;
              })
            )}
          </div>
        ) : (
          <p className="text-lg text-center text-white text-xl mt-6">
            İçerik mevcut değil.
          </p>
        )}
      </div>
    </div>
  );
};

export default ContentPage;
