import {
  collection,
  doc,
  getDocs,
  addDoc,
  orderBy,
  query,
  setDoc,
  deleteDoc,
  updateDoc,
  increment,
  where,
  getDoc,
  arrayRemove,
  deleteField,
  Timestamp,
  onSnapshot,
  Firestore,
  arrayUnion,
  serverTimestamp
} from "firebase/firestore";

import { getStorage, ref, uploadBytes, getDownloadURL, deleteObject, listAll } from "firebase/storage";
import { firestore, storage } from "../firebase-config";
import { v4 as uuidv4 } from 'uuid'; // UUID için
import QRCode from "qrcode";
import { hashString } from "../components/utilities";
import { findAllByTestId } from "@testing-library/react";
import { combineSlices } from "@reduxjs/toolkit";

// Save user information to Firestore
export const saveUserToFirestore = async (providerData) => {
  try {
    await setDoc(doc(firestore, "users", providerData.uid), providerData, { merge: true });
    // console.log("User saved successfully:", providerData);
  } catch (error) {
    console.error("Error saving user to Firestore:", error);
  }
};

// Upload files to Firebase Storage and save metadata in Firestore
export const uploadFilesToStorage = async (hashedUserId, targetUserId, videoFile, imageFiles) => {
  const maxFiles = 5;

  // Fetch user's existing content count
  const userContent = await fetchTargetUserContent(hashedUserId, targetUserId);
  const existingContentCount = userContent.length;

  if (existingContentCount + (videoFile ? 1 : 0) + imageFiles.length > maxFiles) {
    throw new Error(`Kullanıcı yalnızca ${maxFiles} dosya yükleyebilir. Lütfen içerik siliniz.`);
  }

  // Upload files
  const videoURL = videoFile ? await uploadVideo(storage, hashedUserId, targetUserId, videoFile) : null;
  const imageURLs = imageFiles.length > 0 ? await uploadImages(storage, hashedUserId, targetUserId, imageFiles) : [];

  // Generate public URLs
  const videoPublicURL = videoURL ? await getPublicURL(videoURL) : null;
  const imagePublicURLs = await Promise.all(imageURLs.map(getPublicURL));

  // // Save content metadata in Firestore
  // const contentRef = await addDoc(collection(firestore, `userContent/${hashedUserId}/targetUsers/${targetUserId}/content`), {
  //   videoPath: videoPublicURL,
  //   imagePaths: imagePublicURLs,
  //   createdAt: new Date().toISOString()
  // });

  return {
    // contentId: contentRef.id,
    videoPath: videoPublicURL,
    imagePaths: imagePublicURLs
  };
};

// Function to get public URL for a file
const getPublicURL = async (filePath) => {
  const fileRef = ref(storage, filePath); // Get a reference to the file
  const publicURL = await getDownloadURL(fileRef); // Get the public URL
  return publicURL;
};

// Helper function to upload video
const uploadVideo = async (storage, hashedUserId, targetUserId, videoFile) => {
  const videoRef = ref(storage, `${hashedUserId}/targetUsers/${targetUserId}/${uuidv4()}_${videoFile.name}`);
  await uploadBytes(videoRef, videoFile);
  return await getDownloadURL(videoRef);
};

// Helper function to upload images
const uploadImages = async (storage, hashedUserId, targetUserId, imageFiles) => {
  const imageRefs = imageFiles.map(file => ref(storage, `${hashedUserId}/targetUsers/${targetUserId}/${uuidv4()}_${file.name}`));
  const imageURLs = await Promise.all(imageRefs.map(async (imageRef, idx) => {
    await uploadBytes(imageRef, imageFiles[idx]);
    return await getDownloadURL(imageRef);
  }));
  return imageURLs;
};

// Fetch user uploaded content from Firestore
export const fetchTargetUserContent = async (hashedUserId, targetUserId) => {

  try {
 const querySnapshot = await getDocs(query(collection(firestore, `userContent/${hashedUserId}/targetUsers/${targetUserId}/content`)));
    return querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
  } catch (error) {
    console.error("Error fetching user content:", error);
    throw error;
  }
};

// Fetch user uploaded content from Firestore with real-time updates
export const fetchTargetUserContentRealtime = (hashedUserId, targetUserId, onContentUpdate) => {
  try {
    const contentRef = collection(firestore, `userContent/${hashedUserId}/targetUsers/${targetUserId}/content`);
    const q = query(contentRef);

    // Gerçek zamanlı dinleme başlat
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const content = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      onContentUpdate(content); // İçerik güncellemesini çağır
    });

    return unsubscribe; // Dinlemeyi sonlandırmak için geri dönen fonksiyon
  } catch (error) {
    console.error("Error setting up real-time listener for user content:", error);
    throw error;
  }
};

// Fetch specific targetUser from Firestore and check if targetUserId exists
export const fetchTargetUser = async (userId, targetUserId) => {
  try {
    // Fetch target users from Firestore
    const snapshot = await getDocs(collection(firestore, `users/${userId}/targetUsers`));
    const targetUserExists = snapshot.docs.some(doc => {
    return doc.id === targetUserId;
  });
    return targetUserExists;
  } catch (error) {
    console.error("Abonelik talepleri alınırken hata oluştu:", error);
    throw error;
  }
};

export const getUserFromFirestore = async (userId) => {
  try {
    const userRef = doc(firestore, "users", userId); // Assuming users are stored under 'users' collection
    const userDoc = await getDoc(userRef);
    if (userDoc.exists()) {
      return { id: userDoc.id, ...userDoc.data() }; // Return user data
    } else {
      console.log("No such user!");
      return null; // User not found
    }
  } catch (error) {
    console.error("Error fetching user data:", error);
    throw error; // Re-throw error for handling
  }
};

export const getUserFileCount = async (hashedUserId, targetUserId) => {
  try {
    // Firestore'daki "content" koleksiyonuna referans
    const contentRef = collection(
      firestore,
      `userContent/${hashedUserId}/targetUsers/${targetUserId}/content`
    );
    
    // "pendingContent" koleksiyonuna referans
    const pendingContentRef = collection(
      firestore,
      `pendingContent`
    );
    
    // "content" koleksiyonundaki dokümanları al
    const contentSnapshot = await getDocs(contentRef);
    let videoCount = 0;
    let imageCount = 0;

    // "content" koleksiyonundaki her dokümanın "media" dizisini kontrol et
    contentSnapshot.forEach((doc) => {
      const data = doc.data();
      if (Array.isArray(data.media)) {
        data.media.forEach((mediaItem) => {
          if (mediaItem.type === "video") {
            videoCount += 1;
          } else if (mediaItem.type === "image") {
            imageCount += 1;
          }
        });
      }
    });

    // "pendingContent" koleksiyonundaki dokümanları al
    const pendingContentSnapshot = await getDocs(pendingContentRef);
    let pendingVideoCount = 0;
    let pendingImageCount = 0;

    // "pendingContent" koleksiyonundaki her dokümanın "media" dizisini kontrol et
    pendingContentSnapshot.forEach((doc) => {
      const data = doc.data();
      if (data.targetUserId === targetUserId && Array.isArray(data.media)) {
        data.media.forEach((mediaItem) => {
          if (mediaItem.type === "video") {
            pendingVideoCount += 1;
          } else if (mediaItem.type === "image") {
            pendingImageCount += 1;
          }
        });
      }
    });

    console.log("Video Count:", videoCount + pendingVideoCount);
    console.log("Image Count:", imageCount + pendingImageCount);

    // Toplam video ve resim sayısını döndür
    return { videoCount: videoCount + pendingVideoCount, imageCount: imageCount + pendingImageCount };
  } catch (error) {
    console.error("Error fetching user file count:", error);
    return { videoCount: 0, imageCount: 0 }; // Hata durumunda sıfır döndür
  }
};

export const deleteFileFromFirestoreAndStorage = async (contentId, hashedUserId, targetUserId, filePath) => {
  const storage = getStorage();
  console.log("filepath : ", filePath);
  console.log("Constructed Path:", `userContent/${hashedUserId}/targetUsers/${targetUserId}/content/${contentId}`);

  try {
    // Firestore'daki içerik referansını al
    const contentRef = doc(firestore, "userContent", hashedUserId, "targetUsers", targetUserId, "content", contentId);
    const contentSnap = await getDoc(contentRef);

    if (contentSnap.exists()) {
      const contentData = contentSnap.data();
      console.log("contentData : ", contentData);

      let updatedMedia = contentData.media || []; // Media dizisini al

      // Storage'taki dosyayı sil
      const fileRef = ref(storage, filePath);
      await deleteObject(fileRef);

      // Firestore'daki media dizisinden ilgili medya öğesini kaldır
      updatedMedia = updatedMedia.filter((mediaItem) => mediaItem.path !== filePath);

      // Eğer media dizisi boşsa dökümanı tamamen sil, değilse güncelle
      if (updatedMedia.length === 0) {
        await deleteDoc(contentRef); // Tüm içerik kaldırıldı
        console.log("Tüm içerik kaldırıldı.");
      } else {
        await updateDoc(contentRef, { media: updatedMedia }); // Kalan içerikleri güncelle
        console.log("Media alanı güncellendi:", updatedMedia);
      }

      console.log("Medya dosyası başarıyla silindi.");
    } else {
      console.error("İçerik bulunamadı.");
    }
  } catch (error) {
    console.error("Medya dosyası silinirken hata oluştu:", error);
    throw error;
  }
};

// Function to update user content metadata
export const updateContentMetadata = async (userId, targetUserId, updatedContentData) => {
  try {
    const userRef = doc(firestore, "users", userId, "targetUsers", targetUserId);
    
    // Update user's content metadata (this may vary depending on your Firestore structure)
    await updateDoc(userRef, {
      userContents: updatedContentData, // Güncellenmiş içerik bilgilerini buraya ekleyin
    });

    // console.log('İçerik metadata güncellendi.');
  } catch (error) {
    console.error('Metadata güncellerken hata oluştu: ', error);
  }
};

// Fetch user's subscription status from Firestore
export const fetchTargetUserSubscriptionStatus = async (userId, targetUserId) => {
  try {
    const userRef = doc(firestore, "users", userId, "targetUsers", targetUserId);
    const userSnap = await getDoc(userRef);

    if (userSnap.exists()) {
      const { subscriptionEndDate } = userSnap.data();
      if (subscriptionEndDate && subscriptionEndDate.toDate() > new Date()) {
        return { isActive: true, expiryDate: subscriptionEndDate.toDate() };
      } else {
        return { isActive: false, expiryDate: null };
      }
    } else {
      console.error("Target User not found.");
      return { isActive: false, expiryDate: null };
    }
  } catch (error) {
    console.error("Error fetching subscription status:", error);
    throw error;
  }
};

// Request subscription approval from admin for another user
export const requestSubscriptionApproval = async (userId, userEmail, hashedUserId, userName, targetUserName, targetUserSurname, targetUserId) => {
  try {
    // Reference to the subscriptionRequests collection
    const subscriptionRequestsRef = collection(firestore, "subscriptionRequests");
    await addDoc(subscriptionRequestsRef, {
      requesterId: userId,          // ID of the user making the request
      hashedUserId: hashedUserId,          
      requesterName: userName,
      requesterEmail: userEmail,           
      targetUserName,               // Name of the target user
      targetUserSurname,            // Surname of the target user
      targetUserId,                 // Deterministic unique ID for the target user
      status: "Onayda",
      requestDate: new Date(),
    });

    // console.log("Subscription request sent for target user:", targetUserId);
    return { message: "Abonelik talebi iletildi.", status: "Onayda" };

  } catch (error) {
    console.error("Error requesting subscription approval:", error);
    throw error;
  }
};

// Bekleyen abonelik taleplerini getirme fonksiyonu
export const fetchPendingSubscriptionRequests = async () => {
  try {
    const snapshot = await getDocs(collection(firestore, "subscriptionRequests"));
    return snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
  } catch (error) {
    console.error("Abonelik talepleri alınırken hata oluştu:", error);
    throw error;
  }
};

// Approve subscription request for another user's subscription
export const approveSubscriptionRequest = async (requestId) => {
  try {
    // Retrieve the subscription request to get the target user's details
    const requestDocRef = doc(firestore, "subscriptionRequests", requestId);
    const requestDoc = await getDoc(requestDocRef);

    if (!requestDoc.exists()) {
      throw new Error("Subscription request not found");
    }

    const { requesterId, targetUserName, targetUserSurname, targetUserId } = requestDoc.data();

    // Reference the target user's document within the requester's targetUsers subcollection
    const targetUserDocRef = doc(firestore, "users", requesterId, "targetUsers", targetUserId);
    const targetUserDoc = await getDoc(targetUserDocRef);

    // If no existing subscription exists, create a new document
    if (!targetUserDoc.exists()) {
      // Set new subscription dates
      const startDate = Timestamp.now();
      const endDate = Timestamp.fromDate(new Date(startDate.toDate().setFullYear(startDate.toDate().getFullYear() + 1)));

      await setDoc(targetUserDocRef, {
        name: targetUserName,
        surname: targetUserSurname,
        subscriptionStartDate: startDate,
        subscriptionEndDate: endDate,
      });
      // console.log(`Created new document for target user ${targetUserName} ${targetUserSurname}.`);
    }
    // Remove the subscription request from Firestore
    await deleteDoc(requestDocRef);
    // console.log(`Subscription approved for ${targetUserName} ${targetUserSurname}, and subscription started.`);
  } catch (error) {
    console.error("Error approving subscription:", error);
    throw error;
  }
};

// Cancel subscription request
export const cancelSubscriptionRequest = async (requestId) => {
  try {
    // Retrieve the request to confirm details before deletion
    const requestDocRef = doc(firestore, "subscriptionRequests", requestId);
    const requestDoc = await getDoc(requestDocRef);

    if (!requestDoc.exists()) {
      throw new Error("Subscription request not found");
    }

    const { targetUserName, targetUserSurname } = requestDoc.data();

    // Delete the subscription request document from Firestore
    await deleteDoc(requestDocRef);
    // console.log(`Subscription request for ${targetUserName} ${targetUserSurname} has been canceled.`);
  } catch (error) {
    console.error("Error canceling subscription request:", error);
    throw error;
  }
};

// Kullanıcı kaydolduğunda ContentPage linki oluştur ve QR kodu ekle
export const createUserContentPageLink = async (userId, hashedUserId, targetUserId) => {
  const contentPageLink = `http://msc.barruno.com/content/${encodeURIComponent(hashedUserId)}/${encodeURIComponent(targetUserId)}/`;

  // QR kodu oluştur
  try {
      const qrCodeUrl = await QRCode.toDataURL(contentPageLink, { errorCorrectionLevel: 'H' });

      // Firestore'da user dokümanına link ve qr kodu ekle
      await setDoc(doc(firestore, "users", userId, "targetUsers", targetUserId), {
        contentPageLink,
        qrCodeUrl, // QR kodu base64 olarak ekleniyor
      }, { merge: true });

      return { contentPageLink, qrCodeUrl }; // Link ve QR kodu döndür
    } catch (error) {
      console.error("QR Code generation error:", error);
      throw new Error("Failed to generate QR code");
    }
};

// ContentPage Linkini çağırıp SubscriberComponent'e iletiyor.
export const getContentPageLinkAndQr = async (userId, targetUserId) => {
try {
  const userDoc = await getDoc(doc(firestore, "users", userId, "targetUsers", targetUserId));
  const contentPageLink = userDoc.data()?.contentPageLink;
  const contentPageQr = userDoc.data()?.qrCodeUrl ;
  return {contentPageLink , contentPageQr}
}
  catch (error) {
    console.error("ContentPage link firestore'dan alınırken hata oluştu:", error);
    throw error;
  }
}

// Check Subscription Status and Pending Requests
export const checkSubscriptionStatus = async (targetUserId) => {
  try {
    // Fetch all pending subscription requests
    const pendingRequests = await fetchPendingSubscriptionRequests();
   
    // Check if there is an active or pending request for the target user
    const existingPendingRequest = pendingRequests.find(request => {
      return request.targetUserId === targetUserId;
    });
    
    if (existingPendingRequest) {
      return true
    } else {
      return false
    }
  
  } catch (error) {
    console.error("Error checking subscription status:", error);
    throw new Error("Could not check subscription status");
  }
};

// Fetch all current subscribers
export const fetchSubscribers = async (userId, callback) => {
  try {
    const subscribersCollection = collection(firestore, `users/${userId}/targetUsers`);

    const unsubscribe = onSnapshot(subscribersCollection, (querySnapshot) => {
      const fetchedSubscribers = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      callback(fetchedSubscribers); // Call the provided callback function
    });

    return unsubscribe; // Return the unsubscribe function
  } catch (error) {
    console.error("Error fetching subscribers:", error);
    return null;
  }
};

// Fetch UserBased subscription requests which is pending
export const fetchUserBasedSubscriptionRequests = (userId, callback) => {
  const q = query(
    collection(firestore, "subscriptionRequests"),
    where("requesterId", "==", userId)
  );

  const unsubscribe = onSnapshot(q, (querySnapshot) => {
    const requests = [];
    querySnapshot.forEach((doc) => {
      requests.push({ id: doc.id, ...doc.data() });
    });
    // console.log("requests querySnapshot : ", requests);

    // Callback function control with additional logging
    if (callback && typeof callback === "function") {
      // console.log("Calling callback with requests");
      callback(requests); // Call the callback
    } else {
      console.error("fetchUserBasedSubscriptionRequets : callback is not a function", callback);
    }
  });

  return unsubscribe;
};


//Yeni içerik pendingContent koleksiyonuna eklenmesi
export const requestContentApproval = async (pendingContent) => {
  try {
    // Firestore'a içerik ekle
    await addDoc(collection(firestore, "pendingContent"), pendingContent);

    // Mail gönderimi
    try {
      const response = await fetch("https://www.msc.barruno.com/send-content-approval-notification", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          subscriberEmail: pendingContent.userEmail,
        }),
      });

      if (!response.ok) {
        throw new Error("Mail gönderimi başarısız");
      }

    } catch (mailError) {
      // Mail gönderim hatası sadece konsola yazdırılır, kullanıcıya gösterilmez
      console.error("Mail gönderim hatası:", mailError);
    }

    // Başarılı işlem sonrası kullanıcıya sadece Firestore kaydının başarıyla yapıldığı mesajı döndürülür
    return { message: "İçerik başarıyla kaydedildi.", status: "Onayda" };

  } catch (firestoreError) {
    // Firestore işlemi sırasında hata oluşursa kullanıcıya hata mesajı gösterilir
    console.error("Firestore hata:", firestoreError);
    return { message: "İçerik kaydedilirken bir hata oluştu. Lütfen tekrar deneyin.", status: "Hata" };
  }
};


//Fetch Pending Contents
export const fetchPendingContents = async () => {
  try {
    // Reference to the `pendingContent` collection
    const contentsRef = collection(firestore, "pendingContent");

    // Fetch all documents from the collection
    const querySnapshot = await getDocs(contentsRef);

    // Process the data
    const pendingContents = querySnapshot.docs.map((doc) => {
      const data = doc.data();

      // Simply return the media array without filtering by status
      return {
        id: doc.id,
        ...data,
        media: data.media || [],  // Include all media items (no filter by status)
      };
    });

    // Return only documents that have at least one media item
    return pendingContents.filter((content) => content.media.length > 0);
  } catch (error) {
    console.error("Error fetching pending contents:", error);
    throw error;
  }
};

// Fetch for pending contents Real-time
export const fetchPendingContentsRealtime = (callback) => {
  const contentsRef = collection(firestore, 'pendingContent');

  // Firestore verilerini gerçek zamanlı dinlemek için onSnapshot kullanıyoruz
  return onSnapshot(contentsRef, (querySnapshot) => {
    const pendingContents = querySnapshot.docs.map((doc) => {
      const data = doc.data();
      return {
        id: doc.id,
        ...data,
        media: data.media || [],
      };
    });

    // Callback fonksiyonunu çağırarak verileri component'e gönderiyoruz
    callback(pendingContents);
  });
};


//Approve Media
export const approveMediaItem = async (contentId, mediaPath, hashedUserId, targetUserId) => {
  try {
    const contentRef = doc(firestore, "pendingContent", contentId);
    const contentSnapshot = await getDoc(contentRef);

    if (!contentSnapshot.exists()) {
      throw new Error(`Content with ID ${contentId} not found.`);
    }

    const contentData = contentSnapshot.data();
    const mediaItem = contentData.media.find((item) => item.path === mediaPath);

    if (!mediaItem) {
      throw new Error(`Media item with path ${mediaPath} not found in content with ID ${contentId}.`);
    }

    // Step 1: Update or create the document in userContent
    const targetPath = `userContent/${hashedUserId}/targetUsers/${targetUserId}/content/${contentId}`;
    const targetDocRef = doc(firestore, targetPath);
    const targetDocSnapshot = await getDoc(targetDocRef);

    if (targetDocSnapshot.exists()) {
      // If the document already exists, append the approved media
      const existingData = targetDocSnapshot.data();
      const updatedMedia = [...existingData.media, mediaItem];
      await updateDoc(targetDocRef, { media: updatedMedia });
    } else {
      // If the document doesn't exist, create it with the approved media
      await setDoc(targetDocRef, {
        ...contentData,
        media: [mediaItem], // Start with the approved media
      });
    }

    // Step 2: Remove approved media from pendingContent
    const updatedMedia = contentData.media.filter((item) => item.path !== mediaPath);

    if (updatedMedia.length > 0) {
      await updateDoc(contentRef, { media: updatedMedia });
    } else {
      await deleteDoc(contentRef); // Delete the entire document if no media left
    }

    return { message: "Media approved and processed successfully." };
  } catch (error) {
    console.error("Error approving media item:", error);
    throw error;
  }
};

//Reject Media
export const rejectMediaItem = async (contentId, mediaPath) => {
  try {
    // İçerik dökümanına Firestore'da erişim sağlayın
    const contentRef = doc(firestore, `pendingContent/${contentId}`);

    // Mevcut medya listesini alın
    const contentSnapshot = await getDoc(contentRef);
    if (!contentSnapshot.exists()) {
      throw new Error("İçerik bulunamadı");
    }

    const contentData = contentSnapshot.data();

    // Reddedilecek medya öğesini filtreleyerek çıkarıyoruz
    const updatedMedia = contentData.media.filter((item) => item.path !== mediaPath);

    // Firestore'da medya listesini güncelle
    if (updatedMedia.length > 0) {
      await updateDoc(contentRef, { media: updatedMedia });
    } else {
      // Eğer hiç medya kalmadıysa, içerik dökümanını silebilirsiniz
      await deleteDoc(contentRef);
    }

    // Eğer medya storage'da varsa, o dosyayı silin
    const storageRef = ref(storage, mediaPath);
    await deleteObject(storageRef);

    console.log("Medya öğesi başarıyla reddedildi!");
  } catch (error) {
    console.error("Medya öğesi reddedilirken hata oluştu:", error);
    throw error;
  }
};

//Reject Content
export const rejectContent = async (contentId) => {
  try {
    // "pendingContent" koleksiyonundan sil
    const pendingContentRef = doc(firestore, `pendingContent/${contentId}`);
    await deleteDoc(pendingContentRef);

    console.log("Content rejected and removed from pendingContent.");
  } catch (error) {
    console.error("Error rejecting content:", error);
  }
};

//Teslimat: Fetch deliveryInfo
export const fetchInitialDeliveryData = async (userId, targetUserId) => {
  const docRef = doc(firestore, `users/${userId}/targetUsers/${targetUserId}`);
  const docSnap = await getDoc(docRef);
  if (docSnap.exists()) {
    // console.log("docSnap.data() : ", docSnap.data().deliveryInfo)
    return docSnap.data().deliveryInfo || null;
  }
  return null;
};

// Teslimat bilgilerini kaydeder veya günceller
export const saveDeliveryInfoToFirestore = async (userId, targetUserId, deliveryInfo) => {
  try {
    const docRef = doc(firestore, `users/${userId}/targetUsers/${targetUserId}`); // Hedef targetUser

    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      // Belge varsa `deliveryInfo` alanını güncelle
      await updateDoc(docRef, { deliveryInfo });
    } else {
      // Belge yoksa oluştur
      await setDoc(docRef, { deliveryInfo });
    }

    // return true;
    return { success: true, message: "Teslimat bilgisi başarıyla güncellendi!." };

  } catch (error) {
    console.error("Teslimat bilgisi kaydedilemedi:", error);
    // return false;
    return { success: false, message: "Teslimat bilgisi kaydedilemedi!." };
  }
};

// Yeni plaka talebi kaydetme
export const handleQrPlateStatus = async (
  userId,
  userEmail,
  targetUserId,
  deliveryInfoForPlaka,
  qrCodeUrl,
  contentPageLink,
  maxRequests = 10
) => {
  try {
    // Plaka bilgilerini deliveryInfoForPlaka nesnesinden al
    const { name, surname, phone, address } = deliveryInfoForPlaka;

    // QrPlateStatus koleksiyonunu kontrol et
    const qrPlateStatusRef = collection(firestore, "QrPlateStatus");
    const existingRequestsSnapshot = await getDocs(qrPlateStatusRef);

    // Maksimum talep sayısını kontrol et
    if (existingRequestsSnapshot.size >= maxRequests) {
      return { success: false, message: "Mevcut plakanız veya plaka talebiniz var." };
    }

    // Yeni belge için ID oluştur
    const newRequestRef = doc(qrPlateStatusRef);

    // Belge verilerini oluştur
    const newRequestData = {
      plateRequestId: newRequestRef.id,
      userId, // Talep eden kullanıcı
      targetUserId, // Hedef kullanıcı
      name,
      surname,
      phone,
      address,
      qrCodeUrl, // QR kod URL'si
      contentPageLink, // İçerik sayfası bağlantısı
      status: "Bekliyor", // Başlangıç durumu
      date: serverTimestamp(), // Talep tarihi
    };

    // Firestore'a yeni belgeyi kaydet
    await setDoc(newRequestRef, newRequestData);  // Bu noktada hata olursa, catch bloğuna geçer

    // E-posta gönderim talebi backend'e yapılacak
    try {
      const response = await fetch("https://www.msc.barruno.com/send-qrplate-approval-notification", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          subscriberEmail: userEmail
        }),
      });

      if (!response.ok) {
        throw new Error("Mail gönderimi başarısız");
      }

    } catch (mailError) {
      // Mail gönderim hatası konsola yazdırılacak, kullanıcıya gösterilmeyecek
      console.error("Mail gönderim hatası:", mailError);
    }

    // Başarılı işlemler için cevap döndürülür
    return { message: "QrPlate talebi başarıyla kaydedildi.", status: "Onayda" };

  } catch (firestoreError) {
    // Firestore işleminde hata oluştuysa, hata mesajını kullanıcıya döndür
    console.error("Firestore hata:", firestoreError);

    return { success: false, message: "QrPlate talebi kaydedilirken bir hata oluştu. Lütfen tekrar deneyin." };
  }
};

// Mevcut plaka talebini getiren fonksiyon
export const fetchQrPlateStatus = async () => {
  try {
    const plakaTalepRef = collection(firestore, "QrPlateStatus");
    const plakaTalepSnap = await getDocs(plakaTalepRef);

    // Eğer talep varsa, ilk talebi döndür
    if (!plakaTalepSnap.empty) {
      const requests = plakaTalepSnap.docs.map((doc) => ({
        id: doc.id, // Belge ID'sini de dahil ediyoruz
        ...doc.data(), // Veriyi genişletiyoruz
      }));
     
      return { success: true, data: requests };
    } else {
      return { success: false, message: "Plaka talep bulunamadı." };
    }
  } catch (error) {
    console.error("Mevcut plaka talebi alınamadı:", error);
    return { success: false, message: "Plaka talebi alınırken hata oluştu!" };
  }
};

export const fetchPendingQrPlateStatus = async (statuses) => {
  try {
    const qrPlateCollection = collection(firestore, "QrPlateStatus");

    const qrQuery = query(qrPlateCollection, where("status", "in", statuses));
    const querySnapshot = await getDocs(qrQuery);

    const data = querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
    return { success: true, data };
  } catch (error) {
    console.error("Error fetching QR plate statuses:", error);
    return { success: false, error: error.message };
  }
};

// fetchQrPlateStatusByUserIds - Daha az okuma yapacak şekilde
export const fetchQrPlateStatusByUserIds = async (userId, targetUserId) => {
  try {
    // Firestore sorgusunu filtreleme kriterleriyle oluştur
    const plakaTalepRef = collection(firestore, "QrPlateStatus");
    const filteredQuery = query(
      plakaTalepRef,
      where("userId", "==", userId),
      where("targetUserId", "==", targetUserId)
    );

    // Filtrelenmiş sorguyu çalıştır
    const querySnapshot = await getDocs(filteredQuery);

    if (!querySnapshot.empty) {
      const filteredRequests = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      return { success: true, data: filteredRequests };
    } else {
      return { success: false, message: "Kullanıcıya ait talep bulunamadı." };
    }
  } catch (error) {
    console.error("Kullanıcıya ait plaka talebi alınamadı:", error);
    return { success: false, message: "Talep alınırken hata oluştu!" };
  }
};

// fetchQrPlateStatusByUserIds - OnSnapShot ile...
export const subscribeToQrPlateStatus = (userId, targetUserId, callback) => {
  const plakaTalepRef = collection(firestore, "QrPlateStatus");
  const filteredQuery = query(
    plakaTalepRef,
    where("userId", "==", userId),
    where("targetUserId", "==", targetUserId)
  );

  const unsubscribe = onSnapshot(
    filteredQuery,
    (querySnapshot) => {
      if (!querySnapshot.empty) {
        const filteredRequests = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        callback({ success: true, data: filteredRequests });
      } else {
        callback({ success: false, message: "Kullanıcıya ait talep bulunamadı." });
      }
    },
    (error) => {
      console.error("Firestore'dan veri alınırken hata oluştu:", error);
      callback({ success: false, message: "Veri alınırken hata oluştu." });
    }
  );

  return unsubscribe; // Dinleyiciyi kaldırmak için fonksiyon döndürülür
};

export const deletePlakaTalep = async (plateRequestId) => {
  try {
    // Belirli talep belgesini sil
    const plakaTalepRef = doc(firestore, `QrPlateStatus/${plateRequestId}`);
    await deleteDoc(plakaTalepRef);
    console.log("Talep silindi...")
  } catch (error) {
    console.error("Talep silinemedi:", error);
    throw new Error("Talep silinirken hata oluştu!");
  }
};

export const ApprovePlateRequest = async (plateRequestId) => {
  const plakaTalepRef = doc(firestore, `QrPlateStatus/${plateRequestId}`);
  try {
    // Belirli talep belgesini sil
    await updateDoc(plakaTalepRef, { status: "Onaylandı" }); // Kalan içerikleri güncelle
    console.log("plateRequestId status onaylandı olarak güncellendi:");
  } catch (error) {
    console.error("plateRequestId status onaylandı olarak güncellenemedi", error);
    throw new Error("Talep updateDoc olurken hata oluştu!");
  }
};

export const ShippingPlateRequest = async (plateRequestId, transferCode) => {
  const plakaTalepRef = doc(firestore, `QrPlateStatus/${plateRequestId}`);
  try {
    // Belirli talep belgesini sil
    await updateDoc(plakaTalepRef, { status: "TransferCodeSent", transferCode  });
    console.log("plateRequestId TransferCodeSent olarak güncellendi:", plateRequestId);
  } catch (error) {
    console.error("plateRequestId status TransferCodeSent olarak güncellenemedi", error);
    throw new Error("Talep updateDoc TransferCodeSent olurken hata oluştu!");
  }
};

export const DeliveredPlateRequest = async (plateRequestId) => {
  const plakaTalepRef = doc(firestore, `QrPlateStatus/${plateRequestId}`);
  try {
    // Belirli talep belgesini sil
    await updateDoc(plakaTalepRef, { status: "TeslimEdildi" }); // Kalan içerikleri güncelle
    console.log("plateRequestId TeslimEdildi olarak güncellendi:", plateRequestId);
  } catch (error) {
    console.error("plateRequestId status TeslimEdildi olarak güncellenemedi", error);
    throw new Error("Talep updateDoc TeslimEdildi olurken hata oluştu!");
  }
};

export const fetchUserById = async (userId) => {
  try {
    const userDocRef = doc(firestore, "users", userId);
    const userDoc = await getDoc(userDocRef);

    if (userDoc.exists()) {
      return { success: true, data: userDoc.data() };
    } else {
      return { success: false, message: "Kullanıcı bulunamadı." };
    }
  } catch (error) {
    console.error("Kullanıcı verisi alınırken hata oluştu:", error);
    return { success: false, message: "Bir hata oluştu." };
  }
};




