import React, { useEffect, useState } from "react";
import {
  approveSubscriptionRequest,
  fetchPendingSubscriptionRequests,
  cancelSubscriptionRequest,
  createUserContentPageLink,
  approveMediaItem, // New function to approve individual media
  rejectMediaItem,  // New function to reject individual media
  fetchPendingContents,
  fetchPendingQrPlateStatus,
  rejectContent,
  deletePlakaTalep,
  ApprovePlateRequest,
  ShippingPlateRequest,
  DeliveredPlateRequest,
  fetchUserById, // Fetch pending contents
} from "../utils/FirebaseFunction";
import "../css/index.css";
import LoginForm from "./LoginForm";
import { Link } from "react-router-dom";
import MediaPreview from "./MediaPreview";
import { useStateValue } from "../context/StateProvider";
import UserCart from "./UserCart";


function AdminPage() {
  const [subscriptionRequests, setSubscriptionRequests] = useState([]);
  const [pendingContents, setPendingContents] = useState([]);
  const [qrPlateStatusInfo, setQrPlateStatusInfo] = useState([])
  const [showWaitingSubscriptions, setShowWaitingSubscriptions] = useState(false);
  const [showWaitingContents, setShowWaitingContents] = useState(false);
  const [showWaitingQrPlatesApproval, setShowWaitingQrPlatesApproval] = useState(false);
  const [showWaitingQrPlatesTransferCode, setShowWaitingQrPlatesTransferCode] = useState(false);
  const [showWaitingQrPlatesDeliveryCompletion, setShowWaitingQrPlatesDeliveryCompletion] = useState(false);
  const [showQrPlatesDeliveryCompleted, setShowQrPlatesDeliveryCompleted] = useState(false);
  
  const [{ user }] = useStateValue();
  const [plakaTalepMessage, setPlakaTalepMessage] = useState('');
  const [plakaShippingMessage, setPlakaShippingMessage] = useState('');
  const [plakaWaitingDeliveryMessage, setPlakaWaitingDeliveryMessage] = useState('');
  
  const [userCache, setUserCache] = useState({});

  useEffect(() => {
    const getRequests = async () => {
      try {
        const requests = await fetchPendingSubscriptionRequests();
        setSubscriptionRequests(requests);
      } catch (error) {
        console.error("Abonelik talepleri alınırken hata oluştu:", error);
      }
    };
    getRequests();
  }, []);

  useEffect(() => {
    const getContents = async () => {
      try {
        const contents = await fetchPendingContents();
        setPendingContents(contents);
      } catch (error) {
        console.error("Bekleyen içerikler alınırken hata oluştu:", error);
      }
    };
    getContents();
  }, []);

  useEffect(() => {
    const fetchDataPendingQr = async () => {
      const response = await fetchPendingQrPlateStatus(["Bekliyor", "Onaylandı", "TransferCodeSent", "TeslimEdildi"]);
      if (response.success) {
        setQrPlateStatusInfo(response.data);
      }
    };

    fetchDataPendingQr();
  }, []);

    // Abonelik onaylama işlemi
    const handleApproveSubscription = async (
      requestId,
      userId,
      targetUserId,
      hashedUserId,
      requesterName,
      requesterEmail,
      targetUserName,
      targetUserSurname
    ) => {
      try {
        await approveSubscriptionRequest(requestId, userId);
          const contentPageLink = await createUserContentPageLink(
            userId,
            hashedUserId,
            targetUserId
          );
  
          setSubscriptionRequests(subscriptionRequests.filter((req) => req.id !== requestId));
  
          // Mail gönderimi
          // try {
          //   // const response = await fetch("http://localhost:5000/approve-subscription", {
          //     const response = await fetch("https://www.msc.barruno.com:5000/approve-subscription", {
          //     method: "POST",
          //     headers: {
          //       "Content-Type": "application/json",
          //     },
          //     body: JSON.stringify({
          //       requesterEmail,
          //       targetUserName,
          //       targetUserSurname,
          //     }),
          //   });
  
          //   if (!response.ok) {
          //     throw new Error("Mail gönderimi başarısız");
          //   }
      
          //   return { message: "İçerik başarıyla kaydedildi ve yöneticilere bildirildi.", status: "Onayda" };
      
          //   } catch (mailError) {
          //     console.error("Mail gönderim hatası:", mailError);
          //     return { message: "İçerik başarıyla kaydedildi ancak mail bildirimi gönderilemedi. Lütfen yöneticilere manuel olarak ulaşın.", status: "Mail Hatası" };
          //   } 


        } catch (error) {
          console.error("Abonelik talebi onaylanırken hata oluştu:", error);
        }
      };
  
    // Abonelik reddettme işlemi
    const handleCancelSubscription = async (requestId) => {
      try {
        await cancelSubscriptionRequest(requestId);
        setSubscriptionRequests(subscriptionRequests.filter((req) => req.id !== requestId));
      } catch (error) {
        console.error("Abonelik talebi iptal edilirken hata oluştu:", error);
      }
    };

    // Approve individual media item
    const handleApproveMedia = async (contentId, mediaPath, hashedUserId, targetUserId) => {
      try {
        await approveMediaItem(contentId, mediaPath, hashedUserId, targetUserId);

        setPendingContents((prevContents) => {
          const updatedContents = prevContents.map((content) => {
            if (content.id === contentId) {
              const updatedMedia = content.media.filter((item) => item.path !== mediaPath);
              // If all media is approved, remove content from the list
              if (updatedMedia.length === 0) {
                rejectContent(contentId);  // Firestore deletion
                return null;  // This will be filtered out
              }
              return { ...content, media: updatedMedia };
            }
            return content;
          }).filter((content) => content !== null);  // Filter out deleted content
          
          return updatedContents;
        });
      } catch (error) {
        console.error("Error while approving media:", error);
      }
    };

    // Reject individual media item
    const handleRejectMedia = async (contentId, mediaPath) => {
      try {
        await rejectMediaItem(contentId, mediaPath);

        setPendingContents((prevContents) =>
          prevContents
            .map((content) =>
              content.id === contentId
                ? {
                    ...content,
                    media: content.media.filter((item) => item.path !== mediaPath), // Silinen medya öğesini kaldır
                  }
                : content
            )
            .filter(content => content.media.length > 0) // Medya öğesi olmayan içeriği kaldır
        );
      } catch (error) {
        console.error("Medya öğesi reddedilirken hata oluştu:", error);
      }
    };
 
    const handleApprovePlateRequest = async (plateRequestId) => {
      try {
        await ApprovePlateRequest(plateRequestId); // Firestore işleviniz burada çağrılır.
        setPlakaTalepMessage("Talep başarıyla onaylandı!");
        setTimeout(() => setPlakaTalepMessage(''), 3000);
    
        // Onaylanan talebi güncelle
        setQrPlateStatusInfo((prevRequests) =>
          prevRequests.map((request) =>
            request.plateRequestId === plateRequestId
              ? { ...request, status: "Onaylandı" }
              : request
          )
        );
      } catch (error) {
        console.error("Talep onaylanamadı:", error);
        setPlakaTalepMessage("Talep onaylanırken hata oluştu!");
        setTimeout(() => setPlakaTalepMessage(''), 3000);
      }
    };    

    const handleShippingPlateRequest = async (plateRequestId, transferCode) => {
      if (!transferCode) {
        alert("Lütfen transfer kodunu girin.");
        return;
      }
    
      try {
        await ShippingPlateRequest(plateRequestId, transferCode); // Transfer kodunu da Firestore'a gönder
        setPlakaShippingMessage("Transfer Code iletildi...!");
        setTimeout(() => setPlakaShippingMessage(""), 3000);
    
        // Transfer kodu gönderilen talebi güncelle
        setQrPlateStatusInfo((prevRequests) =>
          prevRequests.map((request) =>
            request.plateRequestId === plateRequestId
              ? { ...request, status: "TransferCodeSent", transferCode }
              : request
          )
        );
      } catch (error) {
        console.error("Transfer Code gönderilemedi:", error);
        setPlakaShippingMessage("Transfer Code gönderilemedi!");
        setTimeout(() => setPlakaShippingMessage(""), 3000);
      }
    };        

    const handleDeliveredPlateRequest = async (plateRequestId) => {
      try {
        await DeliveredPlateRequest(plateRequestId); // Firestore işleviniz burada çağrılır.
        setPlakaWaitingDeliveryMessage("Teslimat başarılı...!");
        setTimeout(() => setPlakaWaitingDeliveryMessage(''), 3000);
    
        // Teslim edilen talebi güncelle
        setQrPlateStatusInfo((prevRequests) =>
          prevRequests.map((request) =>
            request.plateRequestId === plateRequestId
              ? { ...request, status: "TeslimEdildi" }
              : request
          )
        );
      } catch (error) {
        console.error("Teslimat başarılı değil...", error);
        setPlakaWaitingDeliveryMessage("Teslimatta hata oluştu!");
        setTimeout(() => setPlakaWaitingDeliveryMessage(''), 3000);
      }
    };
    
    

   //Delete_Reject Plate Request
    const handleDeletePlateRequest = async (plateRequestId) => {

      try {
        // Talebi Firestore'dan silme işlemi
        await deletePlakaTalep(plateRequestId); // Firestore işleviniz burada çağrılır.
        setPlakaTalepMessage("Talep başarıyla silindi!");
        setTimeout(() => setPlakaTalepMessage(''), 3000);

        // Silinen belgeyi listeden kaldır
        setQrPlateStatusInfo((prevRequests) =>
          prevRequests.filter((request) => request.plateRequestId !== plateRequestId)
        );

      } catch (error) {
        console.error("Talep silinemedi:", error);
        setPlakaTalepMessage("Talep silinirken hata oluştu!");
        setTimeout(() => setPlakaTalepMessage(''), 3000);
      }
    };

    const [selectedUser, setSelectedUser] = useState(null);
    const [activeUserCart, setActiveUserCart] = useState(null);



    const handleUserClick = async (userId, requestStatus) => {
      setActiveUserCart(requestStatus);
    
      // Eğer aynı kullanıcı seçildiyse, kartı gizle
      if (selectedUser && selectedUser.userId === userId) {
        setSelectedUser(null);
        setActiveUserCart(null);
        return;
      }
    
      // Eğer userCache'de kullanıcı yoksa, Firestore'dan al
      let userDoc = userCache[userId];
    
      if (!userDoc) {
        try {
          userDoc = await fetchUserById(userId); // Firestore'dan veri çek
          if (userDoc) {
            // Yeni kullanıcıyı cache'e ekle
            setUserCache(prevCache => ({
              ...prevCache,
              [userId]: userDoc
            }));
          }
        } catch (error) {
          console.error("Kullanıcı verisi alınırken bir hata oluştu:", error);
        }
      }
    
      if (userDoc) {
        setSelectedUser({ ...userDoc, userId });
      }
    };
    
  return (
    <>
      {/* Main Div */}
      <div className="max-w-4xl mx-auto mt-4 p-6 bg-white rounded-lg shadow-lg">
        {/* LoginForm Butonu */}
        <div className="bg-blue-100 font-bold p-2 mb-2 flex justify-between items-center">
          <h2 className="text-left">Sayın {user.displayName}</h2>
          <div className="flex items-center">
            <Link to={`/userContent`} className="text-blue-600 py-2 mr-4 hover:underline">Yüklenen İçerik</Link>
            <LoginForm buttonClass="relative" />
          </div>
        </div>

        {/* 1- Pending Subscriptions waiting for Approval */}
        <div className='bg-orange-100 p-2 mb-2'>
          <a
            href="#"
            onClick={(e) => {
              e.preventDefault(); // Bağlantının varsayılan davranışını engeller
              setShowWaitingSubscriptions(!showWaitingSubscriptions);
            }}
            className="font-semibold text-lg hover:bg-green-300 transition-colors duration-200"
          >            
            <span className="underline mr-2">Onay Bekleyen Abonelik Talepleri</span>
            <span>[<span className="font-semibold text-red-700"> {subscriptionRequests ? subscriptionRequests.length
              : 0} adet </span>]</span>
          </a>
          {showWaitingSubscriptions && (
            subscriptionRequests.length > 0 ? (
              subscriptionRequests.map((req) => (
                <div
                  key={req.id}
                  className="subscription-request border p-4 mb-4 rounded shadow"
                >
                  <p>
                    <strong>Talep eden kullanıcı İsmi/Soyismi:</strong> {req.requesterName}
                  </p>
                  <p>
                    <strong>Talep edilen kullanıcı İsmi/Soyismi:</strong> {req.targetUserName}{" "}
                    {req.targetUserSurname}
                  </p>
                  <button
                    onClick={() =>
                      handleApproveSubscription(
                        req.id,
                        req.requesterId,
                        req.targetUserId,
                        req.hashedUserId,
                        req.requesterName,
                        req.requesterEmail,
                        req.targetUserName,
                        req.targetUserSurname
                      )
                    }
                    className="bg-blue-500 text-white mt-2 px-4 py-2 mr-2 rounded hover:bg-blue-600"
                  >
                    Onayla
                  </button>
                  <button
                    onClick={() => handleCancelSubscription(req.id)}
                    className="bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600"
                  >
                    İptal Et
                  </button>
                </div>
              ))
            ) : (
              <p>Onay bekleyen abonelik talebi yok.</p>
            )
          )}
        </div>

        {/* 2- Pending Contents waiting for Approval */}
        <div className='bg-gray-100 p-2 mb-2'>
        <a
            href="#"
            onClick={(e) => {
              e.preventDefault(); // Bağlantının varsayılan davranışını engeller
              setShowWaitingContents(!showWaitingContents);
            }}
            className="font-semibold text-lg hover:bg-green-300 transition-colors duration-200"
          >
            <span className="underline mr-2">Onay Bekleyen İçerik Talepleri</span>
            <span>[<span className="font-semibold text-red-700"> {pendingContents ? pendingContents.length
              : 0} adet </span>]</span>            
          </a>

          
          {showWaitingContents && (
            pendingContents.length > 0 ? (
              pendingContents.map((content) => (
                <div
                  key={content.id}
                  className="content-request border p-4 mb-4 rounded shadow"
                >
                  <p><strong>Kullanıcı Adı:</strong> {content.userName}</p>
                  <p><strong>Kullanıcı Mail Adresi:</strong> {content.userEmail}</p>

                  <h3 className="text-lg font-bold mt-4">Medya</h3>
                  {content.media.map((media, index) => (
                    <MediaPreview
                      key={index}
                      media={media}
                      onApprove={() =>
                        handleApproveMedia(content.id, media.path, content.hashedUserId, content.targetUserId)
                      }
                      onReject={() => handleRejectMedia(content.id, media.path)}
                    />
                  ))}
                </div>
              ))
            ) : (
              <p>Onay bekleyen içerik talebi yok.</p>
            )
          )}
        </div>

        {/* 3- PendingPlates waiting for Approval */}
        <div className="bg-green-100 p-2 mb-2">
          <a
            href="#"
            onClick={(e) => {
              e.preventDefault(); // Bağlantının varsayılan davranışını engeller
              setShowWaitingQrPlatesApproval(!showWaitingQrPlatesApproval);
            }}
            className="font-semibold text-lg hover:bg-green-300 transition-colors duration-200"
          >
            
            <span className="underline mr-2">Onay Bekleyen Qr Etiketler</span>   
           <span>[<span className="font-semibold text-red-700"> {qrPlateStatusInfo
        ? qrPlateStatusInfo.filter((request) => request.status === "Bekliyor").length
        : 0} adet </span>
           ]</span>
          </a>
          {showWaitingQrPlatesApproval && (
            (() => {
              const filteredQrPlates = qrPlateStatusInfo.filter(
                (request) => request.status === "Bekliyor"
              );
            return filteredQrPlates.length > 0 ? (
              <div className="overflow-x-auto text-sm sm:text-base">
                <table className="table-auto border-collapse border border-gray-300 mb-4">
                  <thead>
                    <tr>
                      <th className="border border-gray-300 px-1 font-semibold">İsim</th>
                      <th className="border border-gray-300 px-1 font-semibold">Soyisim</th>
                      <th className="border border-gray-300 px-1 font-semibold">Telefon</th>
                      <th className="border border-gray-300 px-1 font-semibold">Adres</th>
                      <th className="border border-gray-300 px-1 font-semibold">Durum</th>
                      <th className="border border-gray-300 px-1 font-semibold">İşlemler</th>
                    </tr>
                  </thead>
                  <tbody>
                    {filteredQrPlates
                      .filter((request) => request.status === "Bekliyor") // Sadece "Bekliyor" durumunda olanları listele
                      .map((request) => (
                        <tr key={request.id}>
                          <td className="border border-gray-300 px-1 text-center">
                          <a
                              href="#"
                              onClick={(e) => {
                                e.preventDefault();
                                handleUserClick(request.userId, request.status); // Kullanıcı tıklandığında çağrılacak
                              }}
                              className="text-blue-600 underline hover:text-blue-800"
                            >
                              {request.name}
                            </a>
                          </td>
                          <td className="border border-gray-300 px-1">{request.surname}</td>
                          <td className="border border-gray-300 px-1">{request.phone}</td>
                          <td className="border border-gray-300 px-1">{request.address}</td>
                          <td className="border border-gray-300 px-1">{request.status}</td>
                          <td className="border border-gray-300 px-1 py-1 text-center space-y-2">
                            <button
                              onClick={() => handleApprovePlateRequest(request.id)}
                              className="px-1 py-0 md:py-1 mr-1 bg-blue-600 text-white text-sm rounded hover:bg-blue-700"
                            >
                              Onayla
                            </button>
                            <button
                              onClick={() => handleDeletePlateRequest(request.id)}
                              className="px-1 py-0 md:py-1 mr-1 bg-red-600 text-white text-sm rounded hover:bg-red-700"
                            >
                              Sil
                            </button>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
                {/* Kullanıcı Kartı */}
                {activeUserCart === 'Bekliyor' && (
                    <> 
                      {selectedUser && (
                        <UserCart selectedUser={selectedUser}/>
                      )}
                    </>
                  )}


                {plakaTalepMessage && (
                  <div className="text-green-700 font-bold text-lg text-center my-4">
                    {plakaTalepMessage}
                  </div>
                )}
              </div>
            ) : (
              <p>Onay Bekleyen Qr Plaka talebi yok.</p>
            )
          })()
          )}
        </div>

        {/* 4- PendingPlates - Approved and waiting for transfer Code */}
        <div className="bg-green-100 p-2 mb-2">
          <a
            href="#"
            onClick={(e) => {
              e.preventDefault(); // Bağlantının varsayılan davranışını engeller
              setShowWaitingQrPlatesTransferCode(!showWaitingQrPlatesTransferCode);
            }}
            className="font-semibold text-lg hover:bg-green-300 transition-colors duration-200"
          >
            <span className="underline mr-2">Onaylanan ve Transfer Kod Bekleyen Qr Etiketler</span>
            <span>[<span className="font-semibold text-red-700"> {qrPlateStatusInfo
              ? qrPlateStatusInfo.filter((request) => request.status === "Onaylandı").length
              : 0} adet </span>]</span>
          </a>
          {showWaitingQrPlatesTransferCode && (
            (() => {
              const filteredQrPlates = qrPlateStatusInfo.filter(
                (request) => request.status === "Onaylandı"
              );
            return filteredQrPlates.length > 0 ? (
                <div className="overflow-x-auto text-sm sm:text-base">
                  <table className="table-auto border-collapse border border-gray-300 mb-4">
                    <thead>
                      <tr>
                        <th className="border border-gray-300 px-1 font-semibold">İsim</th>
                        <th className="border border-gray-300 px-1 font-semibold">Soyisim</th>
                        <th className="border border-gray-300 px-1 font-semibold">Telefon</th>
                        <th className="border border-gray-300 px-1 font-semibold">Adres</th>
                        <th className="border border-gray-300 px-1 font-semibold">Durum</th>
                        <th className="border border-gray-300 px-1 font-semibold">Transfer Kod</th>
                        <th className="border border-gray-300 px-1 font-semibold">İşlemler</th>
                      </tr>
                    </thead>
                    <tbody>
                      {filteredQrPlates
                        .filter((request) => request.status === "Onaylandı")
                        .map((request) => (
                          <tr key={request.id}>
                            <td className="border border-gray-300 px-4 py-2 text-center">
                            <a
                              href="#"
                              onClick={(e) => {
                                e.preventDefault();
                                handleUserClick(request.userId, request.status); // Kullanıcı tıklandığında çağrılacak
                              }}
                              className="text-blue-600 underline hover:text-blue-800"
                            >
                              {request.name}
                            </a>
                            </td>
                            <td className="border border-gray-300 px-1">{request.surname}</td>
                            <td className="border border-gray-300 px-1">{request.phone}</td>
                            <td className="border border-gray-300 px-1">{request.address}</td>
                            <td className="border border-gray-300 px-1">{request.status}</td>
                            <td className="border border-gray-300 px-1 text-center space-y-2">
                              <div className="flex items-center space-x-2">
                                <input
                                  type="text"
                                  placeholder="Transfer Kod"
                                  id={`transferCode-${request.id}`}
                                  className="border rounded px-1"
                                />
                              </div>
                            </td>
                            <td className="border border-gray-300 px-1 text-center space-y-2">
                              <button
                                onClick={() => {
                                  const transferCode = document.getElementById(`transferCode-${request.id}`).value;
                                  handleShippingPlateRequest(request.id, transferCode);
                                }}
                                className="px-1 bg-yellow-500 text-white text-sm rounded hover:bg-yellow-600"
                              >
                                Kargolandı
                              </button>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                  {/* Kullanıcı Kartı */}
                  {activeUserCart === 'Onaylandı' && (
                    <> 
                      {selectedUser && (
                        <UserCart selectedUser={selectedUser}/>
                      )}
                    </>
                  )}
                  {plakaShippingMessage && (
                    <div className="text-green-700 font-bold text-lg text-center my-4">
                      {plakaShippingMessage}
                    </div>
                  )}
                </div>
              ) : (
                <p>Onay Bekleyen Qr Plaka talebi yok.</p>
              )
            })()
          )}          
        </div>

        {/* 5- PendingPlates - Approved, Transfer Code Sent, waiting for delivery to be completed */}
        <div className="bg-green-100 p-2 mb-2">
          <a
            href="#"
            onClick={(e) => {
              e.preventDefault(); // Bağlantının varsayılan davranışını engeller
              setShowWaitingQrPlatesDeliveryCompletion(!showWaitingQrPlatesDeliveryCompletion);
            }}
            className="font-semibold text-lg hover:bg-green-300 transition-colors duration-200"
          >
            <span className="underline mr-2">Teslimatın tamamlanmasını bekleyen Qr Etiketler</span>   
            <span>[<span className="font-semibold text-red-700"> {qrPlateStatusInfo
        ? qrPlateStatusInfo.filter((request) => request.status === "TransferCodeSent").length
        : 0} adet </span>
            ]</span>
          </a>
          {showWaitingQrPlatesDeliveryCompletion && (
            (() => {
              const filteredQrPlates = qrPlateStatusInfo.filter(
                (request) => request.status === "TransferCodeSent"
              );

              return filteredQrPlates.length > 0 ? (
                <div className="overflow-x-auto text-sm sm:text-base">
                  <table className="table-auto border-collapse border border-gray-300 mb-4">
                    <thead>
                      <tr>
                        <th className="border border-gray-300 px-1 font-semibold">İsim</th>
                        <th className="border border-gray-300 px-1 font-semibold">Soyisim</th>
                        <th className="border border-gray-300 px-1 font-semibold">Telefon</th>
                        <th className="border border-gray-300 px-1 font-semibold">Adres</th>
                        <th className="border border-gray-300 px-1 font-semibold">Durum</th>
                        <th className="border border-gray-300 px-1 font-semibold">Kod</th>
                        <th className="border border-gray-300 px-1 font-semibold">İşlemler</th>
                      </tr>
                    </thead>
                    <tbody>
                      {filteredQrPlates.map((request) => (
                        <tr key={request.id}>
                          <td className="border border-gray-300 px-1 text-center">
                            <a
                              href="#"
                              onClick={(e) => {
                                e.preventDefault();
                                handleUserClick(request.userId, request.status);
                              }}
                              className="text-blue-600 underline hover:text-blue-800"
                            >
                              {request.name}
                            </a>
                          </td>
                          <td className="border border-gray-300 px-1">{request.surname}</td>
                          <td className="border border-gray-300 px-1">{request.phone}</td>
                          <td className="border border-gray-300 px-1">{request.address}</td>
                          <td className="border border-gray-300 px-1">{request.status}</td>
                          <td className="border border-gray-300 px-1">{request.transferCode}</td>
                          <td className="border border-gray-300 px-1 text-center space-y-2">
                            <button
                              onClick={() => handleDeliveredPlateRequest(request.id)}
                              className="px-1 mr-1 bg-green-500 text-white rounded hover:bg-yellow-600"
                            >
                              Müşteriye Teslim Edildi
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  {/* Kullanıcı Kartı */}
                  {activeUserCart === "TransferCodeSent" && selectedUser && (
                    <UserCart selectedUser={selectedUser} />
                  )}

                  {/* Mesaj Kısmı */}
                  {plakaWaitingDeliveryMessage && (
                    <div className="text-green-700 font-bold text-lg text-center my-4">
                      {plakaWaitingDeliveryMessage}
                    </div>
                  )}
                </div>
              ) : (
                <p>Onay Bekleyen Qr Plaka talebi yok.</p>
              );
            })()
          )}

        </div>

        {/* 6- PendingPlates - Delivered to the Customer*/}
        <div className="bg-green-100 p-2 mb-2">
          <a
            href="#"
            onClick={(e) => {
              e.preventDefault(); // Bağlantının varsayılan davranışını engeller
              setShowQrPlatesDeliveryCompleted(!showQrPlatesDeliveryCompleted);
            }}
            className="font-semibold text-lg hover:bg-green-300 transition-colors duration-200"
          >
            <span className="underline mr-2">Müşteriye Teslim Edilmiş Qr Etiketler</span>   
            <span>[<span className="font-semibold text-red-700"> {qrPlateStatusInfo
        ? qrPlateStatusInfo.filter((request) => request.status === "TeslimEdildi").length
        : 0} adet </span>
            ]</span>
          </a>
          {showQrPlatesDeliveryCompleted && (
            (() => {
              const filteredQrPlates = qrPlateStatusInfo.filter(
                (request) => request.status === "TeslimEdildi"
              );


              return filteredQrPlates.length > 0 ? (
              <div className="overflow-x-auto text-sm sm:text-base">
                <table className="table-auto border-collapse border border-gray-300 mb-4">
                  <thead>
                    <tr>
                      <th className="border border-gray-300 px-1 font-semibold">İsim</th>
                      <th className="border border-gray-300 px-1 font-semibold">Soyisim</th>
                      <th className="border border-gray-300 px-1 font-semibold">Telefon</th>
                      <th className="border border-gray-300 px-1 font-semibold">Adres</th>
                      <th className="border border-gray-300 px-1 font-semibold">Durum</th>
                      {/* <th className="border border-gray-300 px-1 font-semibold">İşlemler</th> */}
                    </tr>
                  </thead>
                  <tbody>
                    {filteredQrPlates
                      .filter((request) => request.status === "TeslimEdildi") // Sadece "Bekliyor" durumunda olanları listele
                      .map((request) => (
                        <tr key={request.id}>
                          <td className="border border-gray-300 px-1 text-center">
                          <a
                              href="#"
                              onClick={(e) => {
                                e.preventDefault();
                                handleUserClick(request.userId, request.status); // Kullanıcı tıklandığında çağrılacak
                              }}
                              className="text-blue-600 underline hover:text-blue-800"
                            >
                              {request.name}
                            </a>
                          </td>
                          <td className="border border-gray-300 px-1">{request.surname}</td>
                          <td className="border border-gray-300 px-1">{request.phone}</td>
                          <td className="border border-gray-300 px-1">{request.address}</td>
                          <td className="border border-gray-300 px-1">{request.status}</td>
                          {/* <td className="border border-gray-300 px-1 space-y-2">
                            <p  className="px-1 mr-1 bg-green-600 text-white text-sm rounded">Müşteriye Teslim Edildi</p>
                          </td> */}
                        </tr>
                      ))}
                  </tbody>
                </table>
                {/* Kullanıcı Kartı */}
                {activeUserCart === 'TeslimEdildi' && (
                    <> 
                      {selectedUser && (
                        <UserCart selectedUser={selectedUser}/>
                      )}
                    </>
                  )}     
                {plakaTalepMessage && (
                  <div className="text-green-700 font-bold text-lg text-center my-4">
                    {plakaTalepMessage}
                  </div>
                )}
              </div>
            ) : (
              <p>Teslim Edilen Qr Plaka yok.</p>
            )
          })()
        )}
        </div>



    </div>
    </>
  );
}

export default AdminPage;
